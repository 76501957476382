<template>
  <div class="mt-6 mx-4 bg-white pt-5">
    <div class="max-w-6xl mx-auto mt-6">
      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-3 border border-gray-200 p-8">
          <div class="family-poppins text-base font-medium">
            Payment Details
          </div>
          <div class="family-poppins text-xs font-normal text-gray-600 mt-0.5">
            Complete your purchase by providing your payment details
          </div>
          <div class="grid grid-cols-3">
            <div class="col-span-2">
              <div class="mt-8">
                <div class="family-poppins text-sm font-normal">
                  Email Address
                </div>
                <input
                  v-model="form_data.email"
                  type="email"
                  class="mt-1.5 py-2 px-6 w-full"
                />
              </div>
              <div class="mt-6">
                <div class="family-poppins text-sm font-normal">
                  Your Billing Name
                </div>
                <input
                  v-model="form_data.name"
                  type="email"
                  class="mt-1.5 py-2 px-6 w-full"
                />
              </div>

              <div class="mt-8">
                <div class="family-poppins text-base text-[12px] mt-2">
                  Plan Price
                  <strong
                    >{{ form_data.currency }}{{ form_data.base_amount }}</strong
                  >
                </div>

                <div class="family-poppins text-base font-medium mt-5">
                  Accepted Cards
                </div>
                <div class="flex items-center mt-5">
                  <svg
                    width="46"
                    height="30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M42.358 29.207H3.626c-1.728 0-3.133-1.284-3.133-2.862V3.069C.492 1.491 1.897.207 3.626.207h38.733c1.728 0 3.134 1.284 3.134 2.862v23.275c0 1.58-1.406 2.863-3.135 2.863z"
                        fill="#D4DBE0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.626 2.208c-.521 0-.945.386-.945.861v23.275c0 .476.424.863.945.863H42.36c.521 0 .944-.387.944-.862V3.07c0-.475-.423-.861-.945-.861H3.627z"
                        fill="#fff"
                      />
                      <path
                        d="M18.147 9.359L13.62 20.056h-2.963L8.42 11.52c-.112-.498-.28-.72-.67-.942-.672-.332-1.79-.72-2.74-.887l.056-.332h4.752c.614 0 1.174.388 1.285 1.108l1.174 6.208 2.907-7.26h2.963v-.056zm11.572 7.205c0-2.827-3.913-2.993-3.913-4.212 0-.389.391-.776 1.174-.887.391-.056 1.509-.111 2.739.499l.503-2.273c-.67-.222-1.51-.499-2.628-.499-2.739 0-4.695 1.441-4.695 3.547 0 1.553 1.397 2.384 2.46 2.882 1.061.5 1.453.832 1.453 1.33 0 .721-.838 1.054-1.677 1.054-1.398 0-2.236-.388-2.851-.665l-.503 2.328c.67.277 1.845.554 3.074.554 2.907 0 4.808-1.44 4.864-3.658zm7.267 3.492h2.572L37.32 9.359h-2.403c-.56 0-1.007.332-1.174.776l-4.193 9.92h2.907l.559-1.607h3.577l.392 1.608zm-3.13-3.769l1.453-3.99.839 3.99h-2.292zM22.116 9.36l-2.292 10.697H17.03L19.32 9.359h2.795z"
                        fill="#1A1F71"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <path
                          fill="#fff"
                          transform="translate(.493 .207)"
                          d="M0 0h45v29H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    class="ml-2"
                    width="46"
                    height="30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      clip-path="url(#clip0)"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M42.841 29.207H4.11c-1.728 0-3.133-1.284-3.133-2.862V3.069C.975 1.491 2.38.207 4.109.207h38.733c1.728 0 3.134 1.284 3.134 2.862v23.275c0 1.58-1.406 2.863-3.135 2.863z"
                        fill="#D4DBE0"
                      />
                      <path
                        d="M4.11 2.208c-.522 0-.946.386-.946.861v23.275c0 .476.424.863.945.863h38.733c.522 0 .944-.387.944-.862V3.07c0-.475-.424-.861-.945-.861H4.11z"
                        fill="#fff"
                      />
                      <path
                        d="M26.175 14.931c0 4.682-3.727 8.477-8.325 8.477-4.598 0-8.325-3.795-8.325-8.477 0-4.681 3.727-8.477 8.325-8.477 4.598 0 8.325 3.795 8.325 8.477z"
                        fill="#ED1D24"
                      />
                      <path
                        d="M37.425 14.931c0 4.682-3.727 8.477-8.325 8.477-4.598 0-8.325-3.795-8.325-8.477 0-4.681 3.727-8.477 8.325-8.477 4.598 0 8.325 3.795 8.325 8.477z"
                        fill="#FAAB1D"
                      />
                      <path
                        d="M23.475 21.575c1.659-1.584 2.7-3.872 2.7-6.416 0-2.543-1.041-4.831-2.7-6.414-1.658 1.584-2.7 3.87-2.7 6.414 0 2.545 1.042 4.832 2.7 6.416z"
                        fill="#FF5E00"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <path
                          fill="#fff"
                          transform="translate(.976 .207)"
                          d="M0 0h45v29H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    class="ml-2"
                    width="46"
                    height="30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      clip-path="url(#clip0)"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M42.324 29.207H3.592c-1.728 0-3.134-1.284-3.134-2.862V3.069C.458 1.491 1.863.207 3.592.207h38.733c1.728 0 3.133 1.284 3.133 2.862v23.275c0 1.58-1.405 2.863-3.134 2.863z"
                        fill="#D4DBE0"
                      />
                      <path
                        d="M3.591 2.208c-.52 0-.945.386-.945.861v23.275c0 .476.424.863.945.863h38.734c.52 0 .943-.387.943-.862V3.07c0-.475-.423-.861-.945-.861H3.592z"
                        fill="#fff"
                      />
                      <path
                        d="M6.864 12.012h-1.6v5.5h1.59c.845 0 1.455-.198 1.99-.635a2.764 2.764 0 001.012-2.112c0-1.625-1.23-2.753-2.992-2.753zm1.271 4.13c-.344.306-.786.438-1.49.438h-.292v-3.637h.293c.703 0 1.128.124 1.49.446.375.33.6.84.6 1.368 0 .528-.225 1.057-.6 1.386z"
                        fill="#001722"
                      />
                      <path
                        d="M23.747 11.909c-1.642 0-2.974 1.264-2.974 2.826 0 1.66 1.274 2.9 2.974 2.9 1.657 0 2.966-1.257 2.966-2.868 0-1.601-1.3-2.859-2.966-2.859"
                        fill="#F88200"
                      />
                      <path
                        d="M10.359 12.012h1.087v5.5h-1.087v-5.5zm3.747 2.11c-.653-.24-.845-.397-.845-.692 0-.347.341-.61.81-.61.327 0 .594.131.88.444l.568-.733a2.456 2.456 0 00-1.64-.611c-.986 0-1.739.677-1.739 1.575 0 .76.351 1.146 1.372 1.51.426.147.643.246.752.313.218.14.327.338.327.57 0 .445-.36.774-.846.774-.518 0-.936-.255-1.187-.733l-.701.668c.5.725 1.102 1.047 1.93 1.047 1.13 0 1.924-.743 1.924-1.806 0-.874-.367-1.27-1.605-1.716zm1.947.643c0 1.617 1.288 2.87 2.944 2.87.468 0 .87-.09 1.364-.32v-1.263c-.435.429-.82.602-1.314.602-1.095 0-1.873-.784-1.873-1.897 0-1.055.803-1.889 1.823-1.889.518 0 .912.182 1.364.619v-1.263c-.477-.238-.87-.336-1.339-.336-1.647 0-2.969 1.278-2.969 2.877zm13.121.941l-1.488-3.694h-1.188l2.367 5.64h.585l2.409-5.64h-1.178l-1.506 3.694zm3.18 1.806h3.085v-.931H33.44v-1.486h1.921v-.931h-1.921v-1.22h1.998v-.932h-3.086v5.5zm7.39-3.877c0-1.03-.718-1.623-1.972-1.623h-1.615v5.5h1.089v-2.21h.142l1.504 2.21h1.338l-1.757-2.317c.82-.165 1.271-.718 1.271-1.56zm-2.183.908h-.316v-1.666h.334c.679 0 1.047.28 1.047.816 0 .553-.368.85-1.064.85z"
                        fill="#001722"
                      />
                      <path
                        d="M40.511 12.421c0-.095-.065-.148-.184-.148h-.159v.485h.117v-.189l.137.188h.146l-.164-.2a.134.134 0 00.107-.136zm-.205.067h-.021v-.126h.022c.058 0 .088.02.088.062 0 .042-.03.064-.089.064z"
                        fill="#231F20"
                      />
                      <path
                        d="M40.35 12.09a.429.429 0 00-.302.125.421.421 0 00.302.722.429.429 0 00.427-.423.429.429 0 00-.427-.423zm0 .771a.348.348 0 01-.32-.214.342.342 0 01-.025-.132c0-.194.152-.347.344-.347.185 0 .338.157.338.347 0 .19-.153.346-.338.346z"
                        fill="#231F20"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <path
                          fill="#fff"
                          transform="translate(.458 .207)"
                          d="M0 0h45v29H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    class="ml-2"
                    width="46"
                    height="30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      clip-path="url(#clip0)"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M43.129.208H3.754C2.207.208.94 1.45.94 2.969v23.477c0 1.518 1.266 2.761 2.813 2.761h39.375c1.547 0 2.812-1.242 2.812-2.761V2.969c0-1.518-1.265-2.761-2.812-2.761z"
                        fill="#3498D8"
                      />
                      <path
                        d="M10.318 15.116h2.24l-1.12-2.72-1.12 2.72zm31.21-5.17H35.79l-1.4 1.497-1.26-1.496H20.955l-1.12 2.448-1.12-2.448h-4.898v1.088l-.56-1.088H9.06L5 19.467H9.9l.56-1.496h1.399l.56 1.496h5.458V18.38l.42 1.087h2.8l.419-1.223v1.223h11.196l1.4-1.496 1.26 1.496h5.737l-3.64-4.76 4.06-4.76zm-16.934 8.16h-1.54v-5.303l-2.38 5.304h-1.399l-2.379-5.304v5.304h-3.22l-.56-1.496H9.759l-.559 1.496h-1.82l2.94-6.8h2.379l2.66 6.392v-6.392h2.658l2.1 4.625 1.959-4.625h2.659v6.8h-.14zm13.855 0h-2.1l-1.82-2.311-2.098 2.312h-6.298v-6.8h6.438l1.959 2.176 2.1-2.176h1.959l-3.08 3.4 2.94 3.4zm-10.777-5.439v1.224h3.499v1.36h-3.5v1.36h3.92l1.819-2.04-1.68-1.904h-4.058z"
                        fill="#fff"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <path
                          fill="#fff"
                          transform="translate(.941 .208)"
                          d="M0 0h45v29H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    class="ml-2"
                    width="46"
                    height="30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M42.29 29.207H3.558c-1.728 0-3.134-1.284-3.134-2.862V3.069C.424 1.491 1.83.207 3.558.207H42.29c1.728 0 3.133 1.284 3.133 2.862v23.275c0 1.58-1.405 2.863-3.134 2.863z"
                        fill="#D4DBE0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.557 2.208c-.52 0-.945.386-.945.861v23.275c0 .476.424.863.945.863h38.734c.52 0 .943-.387.943-.862V3.07c0-.475-.423-.861-.945-.861H3.558z"
                        fill="#fff"
                      />
                      <path
                        d="M24.916 23.2c4.59.023 8.778-3.885 8.778-8.64 0-5.199-4.189-8.793-8.778-8.791h-3.949c-4.644-.002-8.465 3.593-8.465 8.791 0 4.756 3.822 8.663 8.465 8.64h3.95z"
                        fill="#0078C0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.059 6.6c-4.277 0-7.742 3.53-7.743 7.884.001 4.355 3.467 7.884 7.743 7.885 4.278-.001 7.744-3.53 7.745-7.885-.001-4.355-3.467-7.884-7.745-7.885zm-4.908 7.884c.005-2.128 1.31-3.943 3.15-4.664v9.328c-1.84-.72-3.146-2.535-3.15-4.664zm6.665-4.665v9.33c1.841-.72 3.149-2.534 3.153-4.665-.004-2.13-1.312-3.945-3.154-4.665z"
                        fill="#fff"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <path
                          fill="#fff"
                          transform="translate(.424 .207)"
                          d="M0 0h45v29H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    class="ml-2"
                    width="46"
                    height="30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      clip-path="url(#clip0)"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path
                        d="M42.773 29.207H4.041c-1.728 0-3.134-1.284-3.134-2.862V3.069C.907 1.491 2.312.207 4.041.207h38.733c1.728 0 3.133 1.284 3.133 2.862v23.275c0 1.58-1.406 2.863-3.134 2.863z"
                        fill="#D4DBE0"
                      />
                      <path
                        d="M4.04 2.208c-.52 0-.944.386-.944.861v23.275c0 .476.424.863.945.863h38.733c.521 0 .944-.387.944-.862V3.07c0-.475-.424-.861-.945-.861H4.04z"
                        fill="#fff"
                      />
                      <path
                        d="M34.656 6.454h-4.11c-1.574 0-2.695 1.242-2.777 2.531v13.859h4.11c1.574 0 2.696-1.242 2.777-2.532V6.454z"
                        fill="#00A650"
                      />
                      <path
                        d="M19.045 6.454h-4.11c-1.575 0-2.696 1.242-2.778 2.531v13.859h4.111c1.574 0 2.695-1.242 2.777-2.532V6.454z"
                        fill="#176FC1"
                      />
                      <path
                        d="M26.85 6.454h-4.11c-1.574 0-2.695 1.242-2.777 2.531v13.859h4.11c1.574 0 2.696-1.242 2.778-2.532V6.454z"
                        fill="#ED1C24"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <path
                          fill="#fff"
                          transform="translate(.907 .207)"
                          d="M0 0h45v29H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>

              <form id="payment-form" @submit.prevent="demo">
                <div class="mt-7">
                  <div class="flex mt-1.5">
                    <!--<div class="h-11 w-24 flex border-l border-t border-b border-gray-500">
                                                <svg class="m-auto" width="38" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path fill-rule="evenodd" clip-rule="evenodd" d="M34.916 24.242H3.069c-1.42 0-2.576-1.063-2.576-2.368V2.61C.492 1.305 1.647.242 3.069.242h31.847c1.421 0 2.577 1.063 2.577 2.369v19.262c0 1.307-1.156 2.37-2.577 2.37z" fill="#D4DBE0"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.069 1.897c-.428 0-.777.32-.777.714v19.262c0 .394.349.714.777.714h31.847c.429 0 .776-.32.776-.713V2.61c0-.393-.348-.714-.777-.714H3.07z" fill="#fff"/><path d="M4.193 14.273h8.88v3.692h-8.88v-3.692zm10.36 0h8.88v3.692h-8.88v-3.692z" fill="#00A4A6"/><path fill-rule="evenodd" clip-rule="evenodd" d="M24.913 14.273h8.88v3.692h-8.88v-3.692z" fill="#00A4A6"/><path d="M28.613 9.104h5.18v2.215h-5.18V9.104z" fill="#111"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="translate(.493 .242)" d="M0 0h37v24H0z"/></clipPath></defs></svg>
                                            </div>
                                            <input type="number" placeholder="Card Number *" class=" py-2 px-6 w-full family-poppins placeholder-gray-900 text-sm font-normal">-->
                    <div id="card-element" class="h-20 w-full">
                      <!-- A Stripe Element will be inserted here. -->
                    </div>
                    <div id="card-errors" role="alert"></div>
                    <input type="hidden" name="plan" value="" />
                  </div>
                </div>

                <div class="flex justify-start">
                  <button
                    @click="pay"
                    id="card-button"
                    class="w-full py-2.5 px-6 bg-red-500 mr-2 text-white focus:outline-none family-poppins text-base font-semibold"
                  >
                    Pay {{ currency_symbol }}{{ form_data.base_amount }}
                  </button>
                  <button
                    @click="cancel"
                    class="w-full py-2.5 px-6 bg-indigo-500 text-black ml-2 focus:outline-none family-poppins text-base font-semibold"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";
import ax from "@/api/tournament";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "Payment",
  components: { loadStripe },

  data() {
    return {
      stripe: {},
      form_data: {
        base_amount: 11111,
        name: "aaa",
        email: "ccc@ccc.com",
      },

      clientSecret: {},
      currency_symbol: "$",
      authUser: "aaaa",
      stripApiKey: "pk_test_TYooMQauvdEDq54NiTphI7jx",
      // stripApiKey : import.meta.env.VITE_STRIPE_PUBLIC_KEY, // this is safe way to deal with keys.
    };
  },
  methods: {
    post_stripeinit() {
      ax.post_stripeinit((flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.form_data = data._stripe;
          console.log("this.form_data", this.form_data);
        } else {
          alert(data.message);
        }
      });
    },

    async stripeLoad() {
      // Noetic Publishable keys:
      this.stripe = await loadStripe(this.stripApiKey);
      console.log("this.stripe", this.stripe);
    },

    paymentIntent() {
      const elements = this.stripe.elements(); // Create an instance of Elements.
      this.cardElement = elements.create("card"); // Create an instance of the card Element.
      // this.clientSecret = this.page_data.client_secret;
      this.cardElement.mount("#card-element"); // Add an instance of the card Element into the `card-element` <div>.

      // Handle real-time validation errors from the card Element.
      this.cardElement.addEventListener("change", function (event) {
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
    },
    pay() {
      ax.post_order_insert((flag, data) => {
        // console.log(flag, data);
        if (flag) {
          alert(data.message);
        } else {
          alert(data.message);
        }
      });
      // this.stripe
      //   .confirmCardPayment(this.form_data, {
      //     payment_method: {
      //       card: this.cardElement,
      //       billing_details: {
      //         name: this.form_data.name,
      //         email: this.form_data.email,
      //       },
      //     },
      //   })
      //   .then((result) => {
      //     if (result.error) {
      //       let payload = {
      //         order_id: "23131RT-12",
      //         uId: 123, // your user id
      //         status: "failed",
      //       };
      //       this.verifyPayment(payload);
      //       Swal.fire("Failed", "Payment failed!", "failed");
      //     } else {
      //       let payload = {
      //         transaction_id: result.paymentIntent.id,
      //         order_id: "23131RT-12",
      //         uId: 123, // your user id
      //         status: "success",
      //       };

      //       this.verifyPayment(payload).then((res) => {
      //         if (res) {
      //           Swal.fire("Success", "Payment Successfully Done.", "success");
      //           this.$inertia.get("/payment/success");
      //         } else {
      //           Swal.fire(
      //             "Warning",
      //             "Unable to verify payment. <br/> Please contact to admin.",
      //             "warning"
      //           );
      //           this.$inertia.get("/payment/failed");
      //         }
      //       });
      //     }
      //   });
    },
    cancel() {
      this.$inertia.get("/payment/failed");
    },
    async verifyPayment(data) {
      await axios
        .post("/api/payment-verification", data)
        .then((response) => {
          if (response.status === 200) {
            resultData = true;
          }
        })
        .catch((response) => {
          resultData = false;
        });
      return resultData;
    },
  },
  async created() {
    this.stripeLoad();
    this.post_stripeinit();
    setTimeout(() => {
      this.paymentIntent();
    }, 3000);
  },
  watch: {},
  mixins: [myMixin],
};

/*
doc: https://stripe.com/docs/invoicing/integration?method=elements
Card : strip pay : 4000056655665556 , Razor-pay 4718 6091 0820 4366 */
</script>

<style scoped>
.base {
  color: "#32325d";
}
.invalid {
  color: "#fa755a";
}
</style>
