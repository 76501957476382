<template>
  <div>
    <location />
    <div class="latest-tourn-wrapper">
      <router-link
        class="latest-tourn-info"
        :to="{
          name: 'Condition',
          params: {
            id:
              tournamenttop.tournamentinfo &&
              tournamenttop.tournamentinfo.tournamentno,
          },
        }"
      >
        <div>
          <span class="tourn-type badge rounded-pill"
            >{{
              tournament_type(
                tournamenttop.tournamentinfo &&
                  tournamenttop.tournamentinfo.type
              )
            }}/
            {{
              tournamenttop.tournamentinfo &&
              tournamenttop.tournamentinfo.tour_rank_type | tour_rank_type
            }}</span
          >
          <h2>
            {{
              tournamenttop.tournamentinfo && tournamenttop.tournamentinfo.title
            }}
          </h2>
          <!-- <p class="info">
            {{
              tournamenttop.tournamentinfo &&
              tournamenttop.tournamentinfo.description
            }}
          </p> -->
          <div class="row row-cols-1 row-cols-sm-auto gy-1">
            <div
              class="col text-date"
              v-if="
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_private
              "
            >
              <!-- 사용자 시간 -->
              <dfn
                class="time-zone-mark badge rounded-pill bg-orange"
                title="Connect time"
                >CT</dfn
              >
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_private
                  | dateformat
              }}
              ~
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_enddate.timezone_private
                  | dateformat
              }}
            </div>
            <div
              class="col text-date"
              v-else-if="
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_common
              "
            >
              <!-- 서비스 시간 -->
              <dfn
                class="time-zone-mark badge rounded-pill bg-green"
                title="Standard time"
                >ST</dfn
              >
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_common
                  | dateformat
              }}
              ~
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_enddate.timezone_common
                  | dateformat
              }}
            </div>
          </div>
        </div>
      </router-link>
      <img
        :src="
          tournamenttop.tournamentinfo &&
          tournamenttop.tournamentinfo.tourbinary &&
          tournamenttop.tournamentinfo.tourbinary[0] &&
          tournamenttop.tournamentinfo.tourbinary[0].filepath
            | get_img(
              'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
            )
        "
      />
    </div>

    <div class="page wrap-1200">
      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-2 row-cols-sm-4 row-cols-lg-6 g-2 justify-content-end"
      >
        <div class="col">
          <select v-model="searchData.pagesize">
            <option value="9" selected>
              {{ $t("filter.common.sort_by_9") }}
            </option>
            <option value="18">{{ $t("filter.common.sort_by_18") }}</option>
            <option value="27">{{ $t("filter.common.sort_by_27") }}</option>
          </select>
        </div>
        <div class="col">
          <select name="type" v-model="searchData.tourstatus_fk">
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("text.common.ready") }}</option>
            <option value="2">{{ $t("text.common.playing") }}</option>
            <option value="3">{{ $t("text.common.completed") }}</option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.tourtype_fk"
          >
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("text.common.status_public") }}</option>
            <option value="2">
              {{ $t("text.common.status_invitational") }}
            </option>
            <!-- <option value="2">매장명</option> -->
            <!-- <option value="3">{{ $t("filter.common.course") }}</option> -->
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.tour_rank_type"
          >
            <option value="-1">{{ $t("filter.common.total") }}</option>
            <option value="0">
              {{ $t("filter.common.low_gross") }} /
              {{ $t("filter.common.low_net") }}
            </option>
            <option value="1">{{ $t("filter.common.low_gross") }}</option>
            <option value="2">
              {{ $t("filter.common.low_net") }}
            </option>
            <!-- <option value="2">매장명</option> -->
            <!-- <option value="3">{{ $t("filter.common.course") }}</option> -->
          </select>
        </div>
        <div class="col-12 col-lg-4 flex-grow-1">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
              :placeholder="
                $t('alert.common.field_enter', {
                  field: $t('title.common.tournament'),
                })
              "
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <div
        v-if="total_cnt > 0"
        class="tourn-card row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-xl-5 gy-5"
      >
        <div class="col" v-for="(item, index) in items" :key="'a' + index">
          <router-link
            :to="{
              name: 'Condition',
              params: {
                id: item.tournamentinfo.tournamentno,
              },
            }"
          >
            <div class="card">
              <div class="card-img-wrapper">
                <img
                  :src="
                    item.tournamentinfo &&
                    item.tournamentinfo.tourbinary &&
                    item.tournamentinfo.tourbinary[0] &&
                    item.tournamentinfo.tourbinary[0].filepath
                      | get_img(
                        'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                      )
                  "
                />
              </div>
              <div class="card-body">
                <span class="tourn-type badge rounded-pill"
                  >{{ tournament_type(item.tournamentinfo.type) }} /
                  {{
                    item.tournamentinfo.tour_rank_type | tour_rank_type
                  }}</span
                >

                <h5 class="card-title">{{ item.tournamentinfo.title }}</h5>
              </div>
              <div class="card-footer">
                <p
                  class="text-truncate text-date"
                  v-if="
                    item.tournamentinfo.timezone_startdate &&
                    item.tournamentinfo.timezone_startdate.timezone_private
                  "
                >
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-orange"
                    title="Connect time"
                    >CT</dfn
                  >
                  {{
                    item.tournamentinfo.timezone_startdate.timezone_private
                      | dateformat
                  }}
                  ~
                  {{
                    item.tournamentinfo.timezone_enddate.timezone_private
                      | dateformat
                  }}
                </p>

                <!-- 서비스 시간 -->
                <p
                  class="text-truncate text-date"
                  v-else-if="
                    item.tournamentinfo.timezone_startdate &&
                    item.tournamentinfo.timezone_startdate.timezone_common
                  "
                >
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-green"
                    title="Standard time"
                    >ST</dfn
                  >
                  {{
                    item.tournamentinfo.timezone_startdate.timezone_common
                      | dateformat
                  }}
                  ~
                  {{
                    item.tournamentinfo.timezone_enddate.timezone_common
                      | dateformat
                  }}
                </p>
              </div>
            </div>
          </router-link>
          <b-button
            size="md"
            variant="primary w-100"
            :aria-label="$t('text.common.participation')"
            @click="participation(item.tournamentinfo.tournamentno)"
          >
            {{ $t("text.common.participation") }}
          </b-button>
        </div>
      </div>

      <div v-else>
        <table class="table board">
          <tbody>
            <tr>
              <td class="text-center">{{ $t("text.common.no_data") }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>

    <b-toast
      id="toast-require-login"
      toaster="position-fixed top-50 start-50 translate-middle"
      no-close-button
      no-auto-hide
      solid
      body-class="p-4 text-center"
    >
      <div class="avatar avatar-lg mt-2 bg-orange border-0">
        <h3 class="material-icons text-white">person</h3>
      </div>
      <h6 class="sub-title mt-3 mb-2">{{ $t("text.common.require_login") }}</h6>
      <p class="pt-1">{{ $t("text.common.go_login_page") }}</p>
      <div class="btn-bottom-wrapper mt-3">
        <b-button
          size="sm"
          variant="outline-secondary"
          :aria-label="$t('button.common.cancel')"
          @click="$bvToast.hide('toast-require-login')"
        >
          {{ $t("button.common.cancel") }}
        </b-button>
        <b-button
          size="sm"
          variant="secondary"
          :aria-label="$t('button.common.login')"
          @click="$router.push('/Login')"
        >
          {{ $t("button.common.login") }}
        </b-button>
      </div>
    </b-toast>

    <b-modal
      id="modal-entry-fee-payment"
      ref="modal-entry-fee-payment"
      size="md"
      centered
      scrollable
      no-close-on-backdrop
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>{{ $t("text.common.entry_fee_payment") }}</h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
        </b-button>
      </template>
      <template v-if="pay_flag == '1'">
        <p class="modal-subtitle lh-sm">2024 1st X-GOLF Champs</p>
        <b-form
          class="filter-group row row-cols-sm-auto g-2 justify-content-end"
        >
          <div class="row g-0 table-type header-col p-keep">
            <div class="col-sm-4">
              {{ $t("title.common.player") }}
            </div>
            <div class="col-sm-8">
              <p class="d-flex flex-wrap">
                <strong class="me-1">{{ $t("text.common.id") }}</strong>
                <span>: test-id</span>
              </p>
              <p class="d-flex flex-wrap mt-1">
                <strong class="me-1">{{ $t("title.common.nickname") }}</strong>
                <span class="text-nickname">: test-nickname</span>
              </p>
            </div>

            <div class="col-sm-4">
              {{ $t("title.common.entry_limit") }}
            </div>
            <div class="col-sm-8 text-red">5 / 5</div>

            <div class="col-sm-4">
              {{ $t("text.common.entry_fee") }}
            </div>
            <div class="col-sm-8 text-price">$ 5.00 AUD</div>

            <div class="col-sm-4">
              {{ $t("text.common.card_info") }}
            </div>
            <div class="col-sm-8 text-price">
              <div class="row g-2">
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="1234 1234 1234 1234"
                    :aria-label="$t('text.common.card_number')"
                    name="CardNumber"
                    autocomplete="off"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="MM / YY"
                    :aria-label="$t('text.common.card_expiry')"
                    name="CardExpiry"
                    autocomplete="off"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="CVC"
                    :aria-label="$t('text.common.card_cvc')"
                    name="CardCVC"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              {{ $t("text.common.cardholder") }}
            </div>
            <div class="col-sm-8">
              <div class="row g-2">
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('title.membership.first_name')"
                    :aria-label="$t('title.membership.first_name')"
                    name=""
                    autocomplete="off"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('title.membership.last_name')"
                    :aria-label="$t('title.membership.last_name')"
                    name=""
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              {{ $t("text.common.country_or_region") }}
            </div>
            <div class="col-sm-8">
              <div class="row g-2">
                <div class="col-12">
                  <select name="countryno">
                    <option value="">{{ $t("title.common.choose") }}</option>
                  </select>
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control text-capitalize"
                    :placeholder="$t('text.common.zip_code')"
                    :aria-label="$t('text.common.zip_code')"
                    name=""
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </template>
      <!-- 결제 정상 완료 -->
      <template v-if="pay_flag == '2'">
        <div class="mt-5 mb-4 text-center">
          <p class="material-icons fs-48 text-success">check_circle</p>
          <h6 class="sub-title my-2 pt-1 text-capitalize">
            {{ $t("text.common.payment_complete") }}
          </h6>
          <p class="pt-1 fs-14 lh-sm">
            {{ $t("text.common.payment_successfully") }}
          </p>
        </div>

        <div class="card">
          <div class="card-body px-3 py-2 fs-14">
            <div class="row g-0 table-type header-col p-keep border-0">
              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.payment_method") }}
              </div>
              <div class="col-sm-8">Credit Card</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.payment_amount") }}
              </div>
              <div class="col-sm-8 text-price">$ 5.00 AUD</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.payment") }} {{ $t("text.common.number") }}
              </div>
              <div class="col-sm-8">1002624537</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.payor") }}
              </div>
              <div class="col-sm-8">지불인</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.date") }}
              </div>
              <div class="col-sm-8 text-date">2024-11-05 05:23:12</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.company") }}
              </div>
              <div class="col-sm-8">XGAU</div>
            </div>
          </div>
        </div>
      </template>
      <!-- 참가 제한 초과 -->
      <template v-if="pay_flag == '0'">
        <div class="my-5 text-center">
          <p class="material-icons fs-48 text-orange">report</p>
          <h6 class="sub-title my-2 pt-1 text-capitalize">
            {{ $t("text.common.entry_limit_exceeded") }}
          </h6>
          <p class="pt-1 fs-14 lh-sm">
            {{ $t("text.common.participate_another_tourn") }}
          </p>
        </div>
      </template>
      <!-- 결제 실패 -->
      <template v-if="pay_flag == '3'">
        <div class="mt-5 mb-4 text-center">
          <p class="material-icons fs-48 text-danger">dangerous</p>
          <h6 class="sub-title my-2 pt-1 text-capitalize">
            {{ $t("text.common.payment_failed") }}
          </h6>
          <p class="pt-1 fs-14 lh-sm">
            {{ $t("text.common.payment_unsuccessful") }}<br />
            {{ $t("text.common.please_try_again") }}
          </p>
        </div>

        <div class="card">
          <div class="card-body px-3 py-2 fs-14">
            <div class="row g-0 table-type header-col p-keep border-0">
              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.error") }} {{ $t("text.common.code") }}
              </div>
              <div class="col-sm-8">404</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.error") }} {{ $t("text.common.reason") }}
              </div>
              <div class="col-sm-8">Card Company Failure</div>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <!-- <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
          {{ $t("button.common.close") }}
        </b-button> -->

        <!-- <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.confirm')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
          {{ $t("button.common.confirm") }}
        </b-button> -->

        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.cancel')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
          {{ $t("button.common.cancel") }}
        </b-button>

        <b-button
          v-if="pay_flag == '1'"
          size="md"
          variant="secondary"
          :aria-label="$t('button.common.submit')"
          type="button"
          @click="onPaySubmit"
        >
          {{ $t("button.common.submit") }}
        </b-button>

        <!-- <b-button
          size="md"
          variant="secondary"
          :aria-label="$t('button.common.try_again')"
        >
          {{ $t("button.common.try_again") }}
        </b-button> -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/tournament";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "TournamentSchedule",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        tourstatus_fk:
          this.$route.query.tourstatus_fk ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tourstatus_fk) ||
          0,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        tourtype_fk:
          this.$route.query.tourtype_fk ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tourtype_fk) ||
          0,
        tour_rank_type:
          this.$route.query.tour_rank_type ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tour_rank_type) ||
          -1,
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          9,
      },
      page_total: 1,
      total_cnt: 0,
      login: sessionStorage.getItem("token") ? true : false,
      items: [],
      tournamenttop: {},
      view_hide: null,
      pay_form: {},
      pay_result: {},
      pay_flag: 1,
    };
  },

  methods: {
    participation(tour_pk) {
      if (!this.login) {
        this.$bvToast.show("toast-require-login");
        return false;
      }
      this.post_stripeinit(tour_pk);
    },

    post_stripeinit(tour_pk) {
      ax.post_stripeinit(tour_pk, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.pay_form.tour_pk = data.tourdefine.tour_pk;
          this.pay_form.clientSecret = data._stripe.clientSecret;
          this.pay_flag = 1;
          this.$bvModal.show("modal-entry-fee-payment");
        } else {
          this.pay_flag = 0;
          alert(data.message);
        }
      });
    },
    onPaySubmit(event) {
      event.preventDefault();

      ax.post_order_insert(this.pay_form, (flag, data) => {
        // console.log(flag, data);
        alert(data.message);
        this.pay_result = data.pay_result;
        if (flag) {
          this.pay_flag = 2;
        } else {
          this.pay_flag = 3;
        }
      });
      // this.stripe
      //   .confirmCardPayment(this.form_data, {
      //     payment_method: {
      //       card: this.cardElement,
      //       billing_details: {
      //         name: this.form_data.name,
      //         email: this.form_data.email,
      //       },
      //     },
      //   })
      //   .then((result) => {
      //     if (result.error) {
      //       let payload = {
      //         order_id: "23131RT-12",
      //         uId: 123, // your user id
      //         status: "failed",
      //       };
      //       this.verifyPayment(payload);
      //       Swal.fire("Failed", "Payment failed!", "failed");
      //     } else {
      //       let payload = {
      //         transaction_id: result.paymentIntent.id,
      //         order_id: "23131RT-12",
      //         uId: 123, // your user id
      //         status: "success",
      //       };

      //       this.verifyPayment(payload).then((res) => {
      //         if (res) {
      //           Swal.fire("Success", "Payment Successfully Done.", "success");
      //           this.$inertia.get("/payment/success");
      //         } else {
      //           Swal.fire(
      //             "Warning",
      //             "Unable to verify payment. <br/> Please contact to admin.",
      //             "warning"
      //           );
      //           this.$inertia.get("/payment/failed");
      //         }
      //       });
      //     }
      //   });
    },
    get_tournamentlist() {
      this.view_hide = null;
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_tournamentlist(this.searchData, (flag, data) => {
        this.total_cnt = data.tournamentlistcnt;
        this.page_total = Math.ceil(
          data.tournamentlistcnt / this.searchData.pagesize
        );
        this.items = data.tournamentlist;
        this.tournamenttop = data.tournamenttop;
        if (flag) {
        } else {
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchname: this.searchData.searchname,
            searchtype: this.searchData.searchtype,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_tournamentlist();
  },
  watch: {
    "searchData.tourtype_fk": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },
    "searchData.tourstatus_fk": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },
    "searchData.tour_rank_type": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },

    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },

    "searchData.pageno": function () {
      this.get_tournamentlist();
    },
  },
  mixins: [myMixin],
};
</script>
